import { AddressSchema, SsnSchema } from "@/schemas";
import { MemberRelationship } from "@/types/api";
import { z } from "zod";

export const EditDependentSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  dob: z.date(),
  ssn: SsnSchema,
  biologicalSex: z.string(),
  address: AddressSchema,
  memberRelationship: z.nativeEnum(MemberRelationship),
  tobaccoUsage: z.boolean().nullable().optional(),
});

export type EditDependent = z.infer<typeof EditDependentSchema>;
