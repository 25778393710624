import { DOCUMENT_TYPES, SUPPLEMENTAL_LOCS, TODAY } from "@/constants";
import { parseDate, toISODateString } from "@/helpers/string";
import { Employer, Enrollment, EnrollmentPlan, Plan } from "@/types/api";
import { Anchor, Flex, Stack, Table, Tabs, Text, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useAtomValue } from "jotai";
import { ACAConsentModal } from "./ACAConsentModal";
import {
  employerDocumentsAtom,
  listBeneficiaryAllocationAtom,
  memberInfoAtom,
} from "./API";
import { BeneficiaryManagement } from "./BeneficiaryManagement";
import CTABanner from "./CTABanner";
import { EnrollmentPlanCard } from "./EnrollmentPlanCard";

export const EmployeeHome = () => {
  const member = useAtomValue(memberInfoAtom[0]);
  const beneficiaryAllocations = useAtomValue(listBeneficiaryAllocationAtom[0]);
  const enrollment = member?.active_enrollment as Enrollment | undefined;
  // Only look at active enrollment plans for now
  const enrollmentPlans = (
    (enrollment?.enrolled_plans || []) as EnrollmentPlan[]
  ).filter(
    (enrollmentPlan) =>
      parseDate(enrollmentPlan.effective_start) <= TODAY &&
      parseDate(enrollmentPlan.effective_end) >= TODAY,
  );
  const documents = useAtomValue(employerDocumentsAtom);
  const [opened, { open, close }] = useDisclosure();

  const medicalPlans = enrollmentPlans.filter(
    (enrolledPlan) =>
      (enrolledPlan.plan as Plan).line_of_coverage === "medical",
  );
  const visionPlans = enrollmentPlans.filter(
    (enrolledPlan) => (enrolledPlan.plan as Plan).line_of_coverage === "vision",
  );
  const dentalPlans = enrollmentPlans.filter(
    (enrolledPlan) => (enrolledPlan.plan as Plan).line_of_coverage === "dental",
  );
  const supplementalPlans = enrollmentPlans.filter((enrolledPlan) =>
    SUPPLEMENTAL_LOCS.includes((enrolledPlan.plan as Plan).line_of_coverage),
  );

  const showCTABanner = member.termination_date
    ? toISODateString(new Date()) < member.termination_date
    : true;

  const employer = member.employer as Employer;

  return (
    <Flex direction="column" gap={24}>
      <Title size="h2">Your Benefits</Title>
      <Text>
        View and manage benefits available to you and your dependents right on
        this page.
      </Text>
      {showCTABanner && <CTABanner />}
      <Tabs defaultValue="benefits">
        <Tabs.List>
          <Tabs.Tab value="benefits">Your Benefits</Tabs.Tab>
          <Tabs.Tab value="documents">Documents</Tabs.Tab>
          {beneficiaryAllocations.length !== 0 && (
            <Tabs.Tab value="beneficiaries">Beneficiaries</Tabs.Tab>
          )}
        </Tabs.List>
        <Tabs.Panel value="benefits">
          <Stack gap="lg" mt="lg">
            {enrollmentPlans.length === 0 && (
              <Stack gap="xs">
                <Title order={4}>
                  You are not enrolled in any health benefits provided by{" "}
                  {employer.trade_name ?? employer.legal_name}.
                </Title>
                <Text c="gray.7" maw={600}>
                  If you are eligible for employer-provided benefits, you can
                  elect and enroll in benefits if you encounter a qualifying
                  life event or during the next renewal period.
                </Text>
              </Stack>
            )}
            <CoverageTable
              title="Medical"
              enrollmentPlans={medicalPlans}
              enrollment={enrollment}
            />
            <CoverageTable
              title="Dental"
              enrollmentPlans={dentalPlans}
              enrollment={enrollment}
            />
            <CoverageTable
              title="Vision"
              enrollmentPlans={visionPlans}
              enrollment={enrollment}
            />
            <CoverageTable
              title="Supplemental Benefits"
              enrollmentPlans={supplementalPlans}
              enrollment={enrollment}
            />
          </Stack>
        </Tabs.Panel>
        <Tabs.Panel value="documents">
          <Title size="h3" mt="lg">
            Documents
          </Title>
          {employer.aca_tracking_enabled && (
            <>
              {member.aca_digital_forms_enabled_at ? (
                <Text>
                  You've opted in to receiving ACA compliance forms digitally.
                  You can always opt out by clicking{" "}
                  <Anchor onClick={open}>here</Anchor>.
                </Text>
              ) : (
                <Text>
                  You've opted out of receiving ACA compliance forms digitally.
                  To opt in, please click <Anchor onClick={open}>here</Anchor>.
                </Text>
              )}
            </>
          )}
          <Table maw={1000} mt="sm">
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Document</Table.Th>
                <Table.Th>Document Type</Table.Th>
                <Table.Th>Uploaded On</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {documents.map((document) => (
                <Table.Tr>
                  <Table.Td
                    style={{
                      maxWidth: "8rem",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                    width={"55%"}
                  >
                    <Anchor href={document.file} target="_blank" size="sm">
                      {document.name}
                    </Anchor>
                  </Table.Td>
                  <Table.Td width={"25%"}>
                    {DOCUMENT_TYPES[document.document_type]}
                  </Table.Td>
                  <Table.Td width={"20%"}>
                    {document.created_at.split("T")[0]}
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </Tabs.Panel>
        {beneficiaryAllocations.length !== 0 && (
          <Tabs.Panel value="beneficiaries">
            <BeneficiaryManagement />
          </Tabs.Panel>
        )}
      </Tabs>
      <ACAConsentModal opened={opened} onClose={close} />
    </Flex>
  );
};

export const CoverageTable = ({
  enrollmentPlans,
  enrollment,
  title,
}: {
  enrollmentPlans: EnrollmentPlan[];
  enrollment: Enrollment;
  title: string;
}) => {
  return enrollmentPlans.length > 0 ? (
    <>
      <Title size="h3">{title}</Title>{" "}
      {enrollmentPlans.map((enrollmentPlan) => (
        <EnrollmentPlanCard
          key={enrollmentPlan.id}
          enrollmentPlan={enrollmentPlan}
          enrollment={enrollment}
        />
      ))}
    </>
  ) : null;
};
