import { RadioGroup } from "@mantine/core";
import { ComponentProps } from "react";

export const OptionalRadioGroup = ({
  children,
  onChange,
  value,
  ...props
}: ComponentProps<typeof RadioGroup>) => {
  const overwrittenOnChange = (value) => {
    let new_value;

    // null value will be empty string
    if (value == "") {
      new_value = null;
    }
    new_value = value === "true";

    onChange(new_value);
  };
  return (
    <>
      <RadioGroup
        {...props}
        value={value?.toString() ?? ""}
        onChange={overwrittenOnChange}
      >
        {children}
      </RadioGroup>
    </>
  );
};
