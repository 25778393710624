import { TODAY } from "@/constants";
import { parseDate, snakeCaseToTitleCase } from "@/helpers/string";
import { Enrollment, EnrollmentPlan, Member, Plan } from "@/types/api";
import { Table } from "@mantine/core";

export const CoverageTable = ({
  enrollmentPlans,
  enrollment,
  member,
}: {
  enrollmentPlans: EnrollmentPlan[];
  enrollment: Enrollment;
  member: Member;
}) => {
  const columnWidths = "20%";
  const rows = [];

  for (const enrollmentPlan of enrollmentPlans) {
    const plan = enrollmentPlan.plan as Plan;
    if (
      parseDate(enrollmentPlan.effective_start) > TODAY ||
      parseDate(enrollmentPlan.effective_end) < TODAY
    )
      continue;

    rows.push(
      <Table.Tr key={enrollmentPlan.id}>
        <Table.Td style={{ width: columnWidths }}>{plan.plan_type}</Table.Td>
        <Table.Td style={{ width: columnWidths }}>{plan.plan_name}</Table.Td>
        <Table.Td style={{ width: columnWidths }}>
          {member.first_name} {member.last_name} (Self)
        </Table.Td>
        <Table.Td style={{ width: columnWidths }}>
          {enrollmentPlan.effective_start}
        </Table.Td>
        <Table.Td style={{ width: columnWidths }}>{enrollment.status}</Table.Td>
      </Table.Tr>,
    );

    // Add dependent rows
    enrollmentPlan.enrolled_dependents.forEach((enrolledDependent) =>
      rows.push(
        <Table.Tr key={enrolledDependent.id}>
          <Table.Td style={{ width: columnWidths }}>{plan.plan_type}</Table.Td>
          <Table.Td style={{ width: columnWidths }}>{plan.plan_name}</Table.Td>
          <Table.Td style={{ width: columnWidths }}>
            {enrolledDependent.dependent.first_name}{" "}
            {enrolledDependent.dependent.last_name} (
            {snakeCaseToTitleCase(
              enrolledDependent.dependent.member_relationship,
            )}
            )
          </Table.Td>
          <Table.Td style={{ width: columnWidths }}>
            {enrollment.effective_start}
          </Table.Td>
          <Table.Td style={{ width: columnWidths }}>
            {enrollment.status}
          </Table.Td>
        </Table.Tr>,
      ),
    );
  }

  return (
    <Table withTableBorder={false}>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Plan Type</Table.Th>
          <Table.Th>Plan Name</Table.Th>
          <Table.Th>Enrollee</Table.Th>
          <Table.Th>Effective Date</Table.Th>
          <Table.Th>Status</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>{rows}</Table.Tbody>
    </Table>
  );
};
