import { CarrierImage } from "@/components";
import { PartnerRelationshipTypes } from "@/constants";
import { Group, LineOfCoverage, PlanQuote } from "@/types/api";
import {
  Alert,
  Anchor,
  Box,
  Button,
  Card,
  Divider,
  Flex,
  Group as MantineGroup,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";
import { useAtom, useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import {
  getEligiblePlansAtom,
  getEnrollmentAtom,
  memberDependentsAtom,
  memberInfoAtom,
} from "../../API";
import { PlanQuoteWithDependents } from "../../EnrollProvider";
import { CoverageCard } from "./CoverageCard";
import { ElectionFormField } from "./ElectionFormField";
import { PaymentDetails } from "./PaymentDetails";
import { useVoluntarySupplementalBenefitCardFormContext } from "./form";
import { isAboveGuaranteedIssue } from "./utils";

export const VoluntarySupplementalBenefitCard = ({
  initialPlanQuote,
  onSubmit,
  selected,
}: {
  initialPlanQuote: PlanQuote;
  onSubmit?: (
    lineOfCoverage: LineOfCoverage,
    selectedQuote: PlanQuoteWithDependents,
  ) => void;
  selected: Map<LineOfCoverage, PlanQuoteWithDependents>;
  planQuoteMap?: Map<string, PlanQuote>;
}) => {
  const [planQuote, setPlanQuote] = useState(initialPlanQuote);
  const [, fetchEligiblePlans] = useAtom(getEligiblePlansAtom);
  const { plan } = planQuote;
  const {
    voluntary_coverage_options: { member, child, spouse },
  } = plan;
  const group = plan.group as Group;
  const dependents = useAtomValue(memberDependentsAtom);
  const memberInfo = useAtomValue(memberInfoAtom[0]);
  const enrollment = useAtomValue(getEnrollmentAtom[0]);
  const form = useVoluntarySupplementalBenefitCardFormContext();
  const requiresEOI = form.values[plan.line_of_coverage].some((election) =>
    isAboveGuaranteedIssue(election, plan.voluntary_coverage_options),
  );
  const elections = form.values[plan.line_of_coverage];
  useEffect(() => {
    fetchEligiblePlans([
      {
        body: {
          dependents: dependents.map(({ id }) => id),
          volume_elections: elections.map((election) => ({
            plan: plan.id,
            amount: election.volume,
            dependent: election.entityId.startsWith("dep_")
              ? election.entityId
              : undefined,
          })),
        },
        enrollmentId: enrollment.id,
      },
    ]).then((quotes: PlanQuote[]) => {
      setPlanQuote(quotes.find((quote) => quote.plan.id === plan.id));
    });
  }, [elections, plan.id, enrollment.id]);

  const header = (
    <>
      <Text c="gray.7" fw="600" size="md">
        {plan.plan_name}
      </Text>
      {plan.plan_summary_url && (
        <Anchor
          c="gray.7"
          fw="400"
          href={plan.plan_summary_url}
          target="_blank"
          size="md"
          underline="always"
        >
          View Plan Summary
        </Anchor>
      )}
    </>
  );

  const handleConfirmSelectionClick = () => {
    // Dependents are captured via the form values instead
    onSubmit(plan.line_of_coverage, { quote: planQuote, dependents: [] });
    form.resetDirty();
  };

  const minimumCoverageCard = (
    <CoverageCard
      title="Minimum Coverage"
      tooltip="The least amount of coverage you may select when enrolling into this plan"
      member={member?.minimum_coverage}
      child={child?.minimum_coverage}
      spouse={spouse?.minimum_coverage}
    />
  );

  const guaranteedIssueCard = (
    <CoverageCard
      title="Guaranteed Issue"
      tooltip="The highest amount of coverage you may select without requiring additional carrier approval"
      member={member?.guaranteed_issue}
      child={child?.guaranteed_issue}
      spouse={spouse?.guaranteed_issue}
    />
  );

  const maximumCoverageCard = (
    <CoverageCard
      title="Maximum Coverage"
      tooltip="The highest amount of coverage you may select when enrolling into this plan"
      member={member?.maximum_coverage}
      child={child?.maximum_coverage}
      spouse={spouse?.maximum_coverage}
    />
  );

  const divider = (
    <Flex align="center" justify="center" style={{ flex: 1 }}>
      <Divider
        className="divider"
        h={25}
        m="auto"
        color="#B9BDBC"
        orientation="vertical"
      />
    </Flex>
  );

  return (
    <Stack gap={20}>
      <Flex justify="space-between">
        <MantineGroup gap="lg">
          <CarrierImage h={75} w={75} carrierId={group.carrier as string} />
          <Stack gap={4} hiddenFrom="md">
            {header}
          </Stack>
          <MantineGroup gap="md" visibleFrom="md">
            {header}
          </MantineGroup>
        </MantineGroup>
        <Box visibleFrom="md">
          <PaymentDetails planQuote={planQuote} />
        </Box>
      </Flex>

      <Stack gap={8}>
        <Title fw={600} c="gray.7" order={6} hiddenFrom="md">
          Key information
        </Title>

        <Card withBorder py={12}>
          <Stack hiddenFrom="sm">
            {minimumCoverageCard}
            {guaranteedIssueCard}
            {maximumCoverageCard}
          </Stack>

          <Stack visibleFrom="sm" hiddenFrom="md">
            <Flex style={{ alignItems: "center" }}>
              {minimumCoverageCard}
              {divider}
              {guaranteedIssueCard}
            </Flex>
            {maximumCoverageCard}
          </Stack>

          <MantineGroup visibleFrom="md">
            {minimumCoverageCard}
            {divider}
            {guaranteedIssueCard}
            {divider}
            {maximumCoverageCard}
          </MantineGroup>
        </Card>
      </Stack>

      {requiresEOI && (
        <Alert
          icon={<IconInfoCircle />}
          title="Additional Information May Be Required"
        >
          One or more members have surpassed the guaranteed issue limit for
          their voluntary life insurance coverage. We may reach out to you for
          further information once you complete your the enrollment process.
        </Alert>
      )}

      <Stack gap={20}>
        <ElectionFormField
          entityId={memberInfo.id}
          coverageOption={member}
          lineOfCoverage={plan.line_of_coverage}
          readOnly={!onSubmit}
        />

        {dependents.map(({ id, member_relationship }) => {
          if (
            PartnerRelationshipTypes.includes(member_relationship) &&
            plan.partner_dependents_eligible
          ) {
            return (
              <ElectionFormField
                entityId={id}
                coverageOption={spouse}
                lineOfCoverage={plan.line_of_coverage}
                key={id}
                readOnly={!onSubmit}
              />
            );
          } else if (plan.child_dependents_eligible)
            return (
              <ElectionFormField
                entityId={id}
                coverageOption={child}
                lineOfCoverage={plan.line_of_coverage}
                key={id}
                readOnly={!onSubmit}
              />
            );
        })}
      </Stack>

      <Box hiddenFrom="md">
        <PaymentDetails planQuote={planQuote} />
      </Box>

      <MantineGroup justify="flex-end">
        {onSubmit && (
          <Button
            onClick={handleConfirmSelectionClick}
            disabled={
              selected.get(plan.line_of_coverage) &&
              !form.isDirty(plan.line_of_coverage)
            }
          >
            Save Selection
          </Button>
        )}
      </MantineGroup>
    </Stack>
  );
};
