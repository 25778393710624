import { getCursor, toISODateString } from "@/helpers";
import { fetchAllPages } from "@/helpers/hooks";
import { clientAtom } from "@/stores";
import { LineOfCoverage, Member, Paginated, Plan } from "@/types/api";
import { mutationAtomType } from "@/types/jotai";
import { atom } from "jotai";
import {
  atomsWithInfiniteQuery,
  atomsWithMutation,
} from "jotai-tanstack-query";
import { atomFamily } from "jotai/utils";

type PlansInfiniteQueryAtomProps = {
  lineOfCoverage?: LineOfCoverage;
  effectiveOn?: string;
  effectiveAfter?: string;
};

const plansInfiniteQueryAtom = atomFamily(
  (params: PlansInfiniteQueryAtomProps) => {
    const { lineOfCoverage, effectiveOn, effectiveAfter } = params;
    const infiniteQueryAtoms = atomsWithInfiniteQuery<Paginated<Plan>>(
      (get) => {
        return {
          queryKey: [
            "planSummaries",
            lineOfCoverage,
            effectiveOn,
            effectiveAfter,
          ],
          queryFn: async ({ pageParam }) => {
            const client = await get(clientAtom);
            return client.getPlans({
              cursor: pageParam as string | undefined,
              expand: ["group.carrier"],
              lineOfCoverage: lineOfCoverage,
              effectiveOn: effectiveOn,
              effectiveAfter: effectiveAfter,
            });
          },
          getNextPageParam: (lastPage) => getCursor(lastPage.next),
          initialPageParam: undefined,
        };
      },
    );
    return atom(infiniteQueryAtoms);
  },
  (
    paramA: PlansInfiniteQueryAtomProps,
    paramB: PlansInfiniteQueryAtomProps,
  ) => {
    return JSON.stringify(paramA) === JSON.stringify(paramB);
  },
);

const membersInfiniteQueryAtom = atomFamily(
  (lineOfCoverage?: LineOfCoverage) => {
    const infiniteQueryAtoms = atomsWithInfiniteQuery<Paginated<Member>>(
      (get) => {
        return {
          queryKey: ["members", lineOfCoverage],
          queryFn: async ({ pageParam }) => {
            const client = await get(clientAtom);
            return client.getMembers({
              cursor: pageParam as string | undefined,
              expand: ["enrollments.enrolled_plans.plan"],
              ...(lineOfCoverage && { lineOfCoverage: lineOfCoverage }),
            });
          },
          getNextPageParam: (lastPage) => getCursor(lastPage.next),
          initialPageParam: undefined,
        };
      },
    );
    return atom(infiniteQueryAtoms);
  },
);

export const medicalPlansAtom = atom<Promise<Plan[]>>(async (get) => {
  const [queryAtom, statusAtom] = get(
    plansInfiniteQueryAtom({ lineOfCoverage: "medical" }),
  );
  return fetchAllPages(await get(queryAtom), get(statusAtom));
});

export const visionPlansAtom = atom<Promise<Plan[]>>(async (get) => {
  const [queryAtom, statusAtom] = get(
    plansInfiniteQueryAtom({ lineOfCoverage: "vision" }),
  );
  return fetchAllPages(await get(queryAtom), get(statusAtom));
});

export const dentalPlansAtom = atom<Promise<Plan[]>>(async (get) => {
  const [queryAtom, statusAtom] = get(
    plansInfiniteQueryAtom({ lineOfCoverage: "dental" }),
  );
  return fetchAllPages(await get(queryAtom), get(statusAtom));
});

export const allPlansAtom = atom<Promise<Plan[]>>(async (get) => {
  const [queryAtom, statusAtom] = get(plansInfiniteQueryAtom({}));
  return fetchAllPages(await get(queryAtom), get(statusAtom));
});

export const activePlansAtom = atom<Promise<Plan[]>>(async (get) => {
  const [queryAtom, statusAtom] = get(
    plansInfiniteQueryAtom({ effectiveOn: toISODateString(new Date()) }),
  );
  return fetchAllPages(await get(queryAtom), get(statusAtom));
});

export const futurePlansAtom = atom<Promise<Plan[]>>(async (get) => {
  const [queryAtom, statusAtom] = get(
    plansInfiniteQueryAtom({ effectiveAfter: toISODateString(new Date()) }),
  );
  return fetchAllPages(await get(queryAtom), get(statusAtom));
});

export const medicalMembersAtom = atom<Promise<Member[]>>(async (get) => {
  const [queryAtom, statusAtom] = get(membersInfiniteQueryAtom("medical"));
  return fetchAllPages(await get(queryAtom), get(statusAtom));
});

export const visionMembersAtom = atom<Promise<Member[]>>(async (get) => {
  const [queryAtom, statusAtom] = get(membersInfiniteQueryAtom("vision"));
  return fetchAllPages(await get(queryAtom), get(statusAtom));
});

export const dentalMembersAtom = atom<Promise<Member[]>>(async (get) => {
  const [queryAtom, statusAtom] = get(membersInfiniteQueryAtom("dental"));
  return fetchAllPages(await get(queryAtom), get(statusAtom));
});

export const allMembersAtom = atom<Promise<Member[]>>(async (get) => {
  const [queryAtom, statusAtom] = get(membersInfiniteQueryAtom(undefined));
  return fetchAllPages(await get(queryAtom), get(statusAtom));
});

export const [, postSubmitConnectionReviewAtom] = atomsWithMutation((get) => ({
  mutationKey: ["postSubmitConnectionReview"],
  mutationFn: async () => {
    const client = await get(clientAtom);
    return await client.submitConnectionReview();
  },
})) as [any, mutationAtomType];
