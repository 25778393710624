import { UsCurrencyFormatter } from "@/helpers/string";
import { PlanQuote } from "@/types/api";
import { Stack, Text, Title } from "@mantine/core";
import { useVoluntarySupplementalBenefitCardFormContext } from "./form";

export const PaymentDetails = ({ planQuote }: { planQuote: PlanQuote }) => {
  const form = useVoluntarySupplementalBenefitCardFormContext();
  const plan = planQuote.plan;
  const elections = form.values[plan.line_of_coverage];
  const enrolleeCount = elections.filter(
    ({ volume }) => Number(volume) > 0,
  ).length;

  return (
    <Stack align="flex-end" gap={0}>
      <Title c="gray.7" fw={600} order={1}>
        {enrolleeCount === 0
          ? "-"
          : UsCurrencyFormatter.format(
              Number(planQuote.per_pay_period_member_contribution),
            )}
      </Title>
      <Text c="gray.7" fw={600} size="sm">
        Per pay period ({enrolleeCount} enrollee{enrolleeCount > 1 && "s"})
      </Text>
    </Stack>
  );
};
