import { isEmbedded } from "@/helpers";
import { snakeCaseToTitleCase } from "@/helpers/string";
import { EditIcon } from "@/icons/Edit";
import { useExternalNavigate } from "@/stores";
import {
  Anchor,
  Button,
  Flex,
  Grid,
  Group,
  Paper,
  Text,
  Title,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useAtomValue } from "jotai";
import { memberDependentsAtom, memberInfoAtom } from "../API";
import { DependentForm } from "../DependentForm";
import { ConfirmMemberInfoForm } from "./ConfirmMemberInfoForm";
import { DependentCard } from "./DependentCard";
import { PersonInfo } from "./PersonInfo";
import { Employer } from "@/types/api";

export const ConfirmDetails = () => {
  const memberInfo = useAtomValue(memberInfoAtom[0]);
  const hasTobaccoRatePlan = (memberInfo.employer as Employer)
    .has_tobacco_rated_plan;
  const navigate = useExternalNavigate();
  const {
    first_name,
    last_name,
    dob,
    biological_sex,
    ssn_last_four,
    tobacco_usage,
  } = memberInfo;
  const dependents = useAtomValue(memberDependentsAtom);
  const [
    createModalOpened,
    { open: openCreateModal, close: closeCreateModal },
  ] = useDisclosure(false);

  const onEditUser = () => {
    navigate("editUser");
  };

  return (
    <Flex direction="column" style={{ marginTop: "20px" }} gap={24}>
      <Title size="h3">1. Ensure your personal information is up to date</Title>
      <Paper p="lg" shadow="xs" withBorder style={{ maxWidth: "24rem" }}>
        <Group justify="space-between">
          <Text fw={600}>
            {first_name} {last_name}
          </Text>
          {isEmbedded() && (
            <Anchor onClick={onEditUser}>
              <EditIcon />
            </Anchor>
          )}
        </Group>
        <PersonInfo
          dob={dob}
          sex={snakeCaseToTitleCase(memberInfo.biological_sex)}
          address={memberInfo.address}
          ssn={
            memberInfo.ssn_last_four
              ? `***-**-${memberInfo.ssn_last_four}`
              : memberInfo.ssn_last_four
          }
        />
      </Paper>

      <ConfirmMemberInfoForm
        biologicalSexVisible={!biological_sex}
        ssnVisible={!ssn_last_four}
        tobaccoUsageVisible={hasTobaccoRatePlan && tobacco_usage === null}
      />

      <Title size="h3">
        {biological_sex && ssn_last_four ? 2 : 3}. Please confirm your
        dependents information
      </Title>
      <Text>
        Even if they don't need coverage, please list every dependent—be it
        spouse, partner, or child.
      </Text>
      <Flex gap={24}>
        <Grid w="100%">
          {dependents.map((dependent) => (
            <Grid.Col
              key={dependent.id}
              span={{ base: 12, sm: 6, md: 4, xl: 3 }}
            >
              <DependentCard dependent={dependent} />
            </Grid.Col>
          ))}

          <Grid.Col span={{ base: 12, sm: 6, md: 4, xl: 3 }}>
            <Paper
              p="lg"
              shadow="xs"
              withBorder
              style={{ height: "100%", minHeight: "18rem" }}
            >
              <Flex
                gap={8}
                justify="space-between"
                direction="column"
                style={{ height: "100%" }}
              >
                <Text fw={600}>Add a new dependent</Text>
                <Button
                  variant="outline"
                  style={{ width: "fit-content" }}
                  onClick={openCreateModal}
                >
                  Add a dependent
                </Button>
              </Flex>
            </Paper>
          </Grid.Col>
        </Grid>
      </Flex>
      <DependentForm opened={createModalOpened} close={closeCreateModal} />
    </Flex>
  );
};
