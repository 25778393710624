import { snakeCaseToTitleCase } from "@/helpers/string";
import { Beneficiary, BeneficiaryAllocation } from "@/types/api";
import { Box, Stack, Table, Text, Title } from "@mantine/core";
import { useAtomValue } from "jotai";
import { listBeneficiaryAllocationAtom } from "../API";

const BeneficiaryManagement = () => {
  const beneficiaryAllocations = useAtomValue(listBeneficiaryAllocationAtom[0]);
  const primaryAllocation = beneficiaryAllocations.find(
    (allocation) => allocation.allocation_type === "primary",
  );
  const contingentAllocation = beneficiaryAllocations.find(
    (allocation) => allocation.allocation_type === "contingent",
  );

  return (
    <Stack p="md" gap="xl" maw={1000}>
      <Box>
        <Stack>
          <Text size="sm">
            <Title order={5}>Primary Beneficiaries</Title>
            Primary beneficiaries are first to receive your benefits and
            insurance proceeds.
          </Text>
          <BeneficiaryAllocationTable
            beneficiaryAllocation={primaryAllocation}
          />
        </Stack>
      </Box>
      <Box>
        <Stack>
          <Text size="sm">
            <Title order={5}>Contingent Beneficiaries</Title>
            Contingent beneficiaries receive your benefit only if no primary
            beneficiaries are eligible.
          </Text>
          <BeneficiaryAllocationTable
            beneficiaryAllocation={contingentAllocation}
          />
        </Stack>
      </Box>
    </Stack>
  );
};

const BeneficiaryAllocationTable = ({
  beneficiaryAllocation,
}: {
  beneficiaryAllocation?: BeneficiaryAllocation;
}) => {
  if (!beneficiaryAllocation) {
    return;
  }
  return (
    <Table maw={1000}>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Full Name</Table.Th>
          <Table.Th>Relationship</Table.Th>
          <Table.Th>Allocation (%)</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {beneficiaryAllocation.splits.map(
          ({ beneficiary, percentage }, index) => {
            beneficiary = beneficiary as Beneficiary;

            return (
              <Table.Tr key={index}>
                <Table.Td w="40%">{beneficiary.full_name}</Table.Td>
                <Table.Td w="40%">
                  {snakeCaseToTitleCase(beneficiary.member_relationship)}
                </Table.Td>
                <Table.Td w="20%">{percentage}%</Table.Td>
              </Table.Tr>
            );
          },
        )}
      </Table.Tbody>
    </Table>
  );
};

export default BeneficiaryManagement;
