import { BiologicalSex } from "@/constants";
import { SsnSchema } from "@/schemas";
import { createFormContext } from "@mantine/form";
import { z } from "zod";

export const ConfirmMemberInfoFormDataSchema = z.object({
  ssn: SsnSchema,
  biological_sex: z.nativeEnum(BiologicalSex),
  tobacco_usage: z.boolean().optional().nullable(),
});

export type ConfirmMemberInfoFormData = z.infer<
  typeof ConfirmMemberInfoFormDataSchema
>;

export const [
  ConfirmMemberInfoFormProvider,
  useConfirmMemberInfoFormContext,
  useConfirmMemberInfoForm,
] = createFormContext<ConfirmMemberInfoFormData>();
