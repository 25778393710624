import { PARTNER_LOGO_URLS } from "@/constants";
import { AxiosResponse } from "axios";
import { useCallback, useEffect, useState } from "react";

export function useQueryParams() {
  const [queryParams, setQueryParams] = useState(
    new URLSearchParams(window.location.search),
  );

  useEffect(() => {
    const handlePopState = () => {
      setQueryParams(new URLSearchParams(window.location.search));
    };

    window.addEventListener("popstate", handlePopState);

    // Clean up the listener when the component is unmounted
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  return queryParams;
}

// Credit to https://github.com/Chalarangelo/30-seconds-of-code/blob/master/content/snippets/react/s/use-hash.md
// Mantine's useHash functionality was not updating on hash changes.
export const useHash = () => {
  const [hash, setHash] = useState(() => window.location.hash);

  const hashChangeHandler = useCallback(() => {
    setHash(window.location.hash);
  }, []);

  useEffect(() => {
    window.addEventListener("hashchange", hashChangeHandler);
    return () => {
      window.removeEventListener("hashchange", hashChangeHandler);
    };
  }, []);

  const updateHash = useCallback(
    (newHash: string) => {
      if (newHash !== hash) window.location.hash = newHash;
    },
    [hash],
  );

  return [hash, updateHash] as const;
};

export function mergeDeep<
  T extends { [key: string]: any },
  R extends { [key: string]: any },
>(obj1: Partial<T> | R, obj2: T): T {
  const output: any = Object.assign({}, obj1);

  for (const key in obj2) {
    if (Object.prototype.hasOwnProperty.call(obj2, key)) {
      if (
        typeof obj2[key] === "object" &&
        obj2[key] !== null &&
        !Array.isArray(obj2[key]) &&
        Object.prototype.hasOwnProperty.call(obj1, key) &&
        typeof obj1[key] === "object" &&
        obj1[key] !== null &&
        !Array.isArray(obj1[key])
      ) {
        output[key] = mergeDeep(obj1[key] as any, obj2[key]);
      } else if (!Object.prototype.hasOwnProperty.call(obj1, key)) {
        output[key] = obj2[key];
      }
    }
  }

  return output;
}

export const snakeCaseKeys = (data) => {
  if (
    typeof data === "object" &&
    data !== null &&
    !(data instanceof FormData)
  ) {
    return Array.isArray(data)
      ? data.map(snakeCaseKeys)
      : Object.fromEntries(
          Object.entries(data).map(([key, value]) => [
            key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`),
            snakeCaseKeys(value),
          ]),
        );
  }
  return data;
};

export const getCursor = (link: string | null) => {
  if (link) {
    const url = new URL(link);
    return url.searchParams.get("cursor");
  }
  return null;
};

export const isEmbedded = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const getClaspApiUrl = (
  environment: "development" | "sandbox" | "production",
) => {
  switch (environment) {
    case "development":
      return import.meta.env.VITE_CLASP_DEVELOPMENT_API_URL;
    case "sandbox":
      return import.meta.env.VITE_CLASP_SANDBOX_API_URL;
    case "production":
      return import.meta.env.VITE_CLASP_PRODUCTION_API_URL;
  }
};

export const downloadFile = (response: AxiosResponse) => {
  const url = window.URL.createObjectURL(response.data);
  const link = document.createElement("a");
  const filename = response.headers["content-disposition"]
    .split("filename=")[1]
    .replaceAll('"', "");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
};

export const getLogoSource = (): string => {
  const domain = window.location.hostname;

  switch (true) {
    case domain !== null && domain.includes("allstate"):
      return PARTNER_LOGO_URLS["Allstate"];
    case domain !== null && domain.includes("bennie"):
      return PARTNER_LOGO_URLS["Bennie"];
    default:
      return import.meta.env.VITE_CLASP_LOGO_URL;
  }
};

export const toISODateString = (date: Date) => date.toISOString().split("T")[0];
