import { BiologicalSex } from "@/constants";
import { AddressSchema, SsnSchema } from "@/schemas";
import { Roles } from "@/types/api";
import { z } from "zod";

enum EmployeeType {
  Salaried = "salaried",
  Hourly = "hourly",
}
export enum PayRateFrequency {
  Hour = "hour",
  Week = "week",
  Month = "month",
  Year = "year",
}

export enum PayFrequency {
  Monthly = "monthly",
  Weekly = "weekly",
  Biweekly = "biweekly",
  Semimonthly = "semimonthly",
}

export const CreateEmployeeDetailsSchema = z.object({
  first_name: z.string().min(1),
  last_name: z.string().min(1),
  dob: z.date(),
  ssn: SsnSchema,
  email: z.string().email(),
  phone_number: z.string().length(10),
  biological_sex: z.nativeEnum(BiologicalSex),
  address: AddressSchema,
  tobacco_usage: z.boolean().nullable().optional(),
});

export const EmployeeAdditionalInformationSchema = z.object({
  hours_worked: z.number().min(0).max(100),
  pay_frequency: z.nativeEnum(PayFrequency),
  address: AddressSchema,
});

export const EditEmployeeDetailsSchema = CreateEmployeeDetailsSchema.omit({
  address: true,
}).extend({
  role: z.enum(Roles),
});

export const EmployeeRoleSchema = z.object({
  hire_date: z.date(),
  employee_type: z.nativeEnum(EmployeeType),
  hours_worked: z.number().min(0).max(100),
  pay: z.number().min(0),
  frequency: z.nativeEnum(PayRateFrequency),
  role: z.enum(Roles),
});
export type CreateEmployeeType = z.infer<typeof CreateEmployeeDetailsSchema> &
  z.infer<typeof EmployeeRoleSchema>;

export type EditEmployeeDetails = z.infer<typeof EditEmployeeDetailsSchema>;
export type EditEmployeeAdditionalInformation = z.infer<
  typeof EmployeeAdditionalInformationSchema
>;
